.App {
  text-align: center;
}

.comingsoon{
  padding: 80px 0px;
}

.text {
  font-family: "serif";
  font-size: 100px;
}


@media (max-width: 576px) {
 .img{
  width: 100%;
  height: auto;
 } 
.text {
  font-family: "serif";
  font-size: 20px;
}
}